<!--
 * @Author: Dyf
 * @LastEditors: wang hai yang
 * @Date: 2023-03-13 09:31:43
 * @LastEditTime: 2023-05-16 17:01:19
 * @Descripttion: 【内容】评语管理
-->
<style lang="scss" scoped>
.remark {
    @include innerPage(56px 34px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 38px), 20px);
    @include pageFoot;

    .page-title {
        .search-form {
            width: 634px;
            margin-left: auto;
            margin-right: 20px;

            .el-form,
            .el-form-item {
                flex-grow: 1;
            }
        }
    }
}

.dialog-container {
    box-sizing: border-box;
    padding: 34px 20px 48px 18px;

    .el-form-item:last-child {
        margin-bottom: 0;
    }
}
</style>

<template>
    <section class="remark">
        <div class="page-title">
            <h3>评语管理</h3>
            <div class="search-form">
                <el-form inline :model="searchForm">
                    <el-form-item>
                        <el-select v-model="searchForm.plrem_type" placeholder="请选择评语类型" clearable>
                            <el-option label="作业评语" value="20" />
                            <el-option label="考试评语" value="10" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchForm.plrem_status" placeholder="请选择" clearable>
                            <el-option label="启用" :value="10" />
                            <el-option label="禁用" :value="20" />
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button type="custom_primary" @click="getData('search')" size="medium">查询</el-button>
            </div>
            <el-button @click="remarkDialog = true,type='add'" class="light" type="custom_primary" size="medium">
                新增+
            </el-button>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" label="评语类型" min-width="18.15%">
                        <template slot-scope="scope">
                            {{ scope.row.plrem_type.split(",").filter(item => item == 10).length > 0 ? "考试评语" : "" }}
                            {{ scope.row.plrem_type.split(",").length > 1 ? "," : "" }}
                            {{ scope.row.plrem_type.split(",").filter(item => item == 20).length > 0 ? "作业评语" : "" }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="plrem_title" label="评语内容" min-width="28.92%" />
                    <el-table-column align="center" label="状态 " min-width="15.31%">
                        <template slot-scope="scope">
                            <p class="table-status"
                                :class="{ success: scope.row.plrem_status == 10, failed: scope.row.status == 20 }">
                                {{ scope.row.plrem_status == 10 && '启用' || '禁用' }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="17.62%">
                        <template slot-scope="scope">
                            <el-button type="custom_primary" size="mini" plain @click="remarkDetail(scope.row.plrem_id)">
                                编辑
                            </el-button>
                            <el-button type="custom_danger" size="mini" @click="deleteRemark(scope.row.plrem_id)" plain>
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='tablePage.pageindex' :total="tablePage.total" @pageChange="flippingPage" />
            </div>
        </div>
        <el-dialog :title="type == 'add' && '添加评语' || '编辑评语'" width="558px" :visible.sync="remarkDialog" @close="hideDialog"
            :show-close="false">
            <div class="dialog-container">
                <el-form label-width="70px" :rules="remarkeRules" ref="remarkForm" :model="remarkForm">
                    <el-form-item label="评语内容" prop="plrem_title">
                        <el-input v-model.trim="remarkForm.plrem_title" type="textarea" maxlength="200"
                            placeholder="请输入内容" />
                    </el-form-item>
                    <el-form-item label="评语分类" prop="plrem_type">
                        <el-checkbox-group v-model="remarkForm.plrem_type">
                            <el-checkbox label="20">作业</el-checkbox>
                            <el-checkbox label="10">考试</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-switch v-model="remarkForm.plrem_status" :active-value="10" :inactive-value="20"
                            :active-text="remarkForm.plrem_status == 10 && '启用' || '禁用'" />
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" :disabled="btnload" @click="handleConfirm"
                    v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import customPagination from "@comp/customPagination"
import { remarkList, remarkEdit, remarkRemove, remarkInfo } from "@api/content"
export default {
    name: 'content_remark',
    components: {
        customPagination
    },
    data() {
        return {
            tableData: [],
            tablePage: {
                pageindex: 1,
                total: 0,
            },
            searchForm: {},
            remarkDialog: false,
            remarkForm: { plrem_type: [], plrem_status: 10 },
            btnload: false,
            remarkeRules: {
                plrem_title: [{ required: true, message: "请输入内容", trigger: "blur" }],
                plrem_type: [{ type: "array", required: true, message: "请选择分类", trigger: "change" }],
            },
        };
    },
    created() {
        this.getData();
    },
    methods: {
        /** 初始化列表数据 */
        async getData(val) {
            if (val == "search") this.tablePage.pageindex = 1;
            let params = {
                ...this.searchForm,
                pageindex: this.tablePage.pageindex
            }
            const { data: res } = await remarkList(params);
            this.tableData = res.data;
            this.tablePage.total = res.allcount;
        },
        /** 切换页码 */
        flippingPage(val) {
            this.tablePage.pageindex = val;
            this.getData();
        },
        /** 删除评语 */
        deleteRemark(id) {
            this.$msgbox({
                title: '删除',
                message: '确认删除当前评语？',
                type: 'warning',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                const res = await remarkRemove(id);
                if (res.status == 200) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '评语已删除！'
                    });
                    this.tablePage.total = this.tablePage.total - 1 < 0 && 0 || this.tablePage.total - 1;
                    if (this.tableData.length == 1 && this.tablePage.pageindex != 1) {
                        this.tablePage.pageindex = this.tablePage.pageindex - 1;
                    }
                    this.getData();
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 评语详情 */
        async remarkDetail(id) {
            const { data } = await remarkInfo(id);
            let formKeys = ['plrem_title', 'plrem_type', 'plrem_status', 'plrem_id'];
            let form = {};
            formKeys.map(key => {
                if (key == 'plrem_type') form[key] = data[key].split(",");
                else form[key] = data[key];
            })
            this.remarkForm = { ...form };
            this.type="updata"
            this.remarkDialog = true;
        },
        /** 确认添加 */
        async handleConfirm() {
            this.$refs.remarkForm.validate(async (valid) => {
                if (valid) {
                    this.btnload = true;
                    let params = {
                        ...this.remarkForm,
                        plrem_type: this.remarkForm.plrem_type.join(","),
                    }
                    const res = await remarkEdit(params);
                    this.btnload = false;
                    if (res) {
                        this.hideDialog();
                        this.$message.success(params.plrem_id && "编辑成功" || "添加成功");
                        this.getData();
                    }
                }
            });
        },
        /** 关闭弹窗 */
        hideDialog() {
            this.remarkDialog = false;
            this.$refs.remarkForm.resetFields();
            this.remarkForm = { plrem_type: [], plrem_status: 10 };
        },
    },
}
</script>